import { customFetch } from "./http";

export let fileUpload = async (url:string, { file, dir, method } :  { file: File, dir: string, method?: string }) : Promise<Record<string, any>> => {

    let formData = new FormData();

    formData.append("file", file);
    formData.append("dir", dir);
    return customFetch(url, {
        method: method ?? 'POST',
        body: formData
    }, true)
    
}
