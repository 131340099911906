import { customFetch } from "../utils/http";

export const getNops = async () => {
  return await customFetch("admin/nop");
};
export const getWinner = async () => {
  return await customFetch("admin/draw");
};
export const updateWinner = async (id: string, prize_id: string) => {
  return await customFetch(`admin/winner_draw`, {
    method: "PUT",
    body: JSON.stringify({ id, prize_id }),
  });
};
