import { useContext, useEffect, useRef, useState, type FC } from "react";
import AuthMid from "../middlewares/AuthMid";
import { LoadingContext } from "../objects/LoadingContext";
import { Resident } from "../model/resident";
import { Pagination } from "../objects/pagination";
import {
  deleteResident,
  editResident,
  getResidents,
  refreshWinner,
} from "../repositories/resident";
import { errorToast, successToast } from "../utils/helper-ui";
import { Button, Modal } from "rsuite";
import CustomTable from "../components/CustomTable";
import { HiOutlinePencilSquare, HiOutlineTrash } from "react-icons/hi2";
import { confirmDelete } from "../utils/helper";
import { fileUpload } from "../utils/file";
import { TfiCup } from "react-icons/tfi";
import { LuRefreshCcw } from "react-icons/lu";

interface ResidentPageProps {}

const ResidentPage: FC<ResidentPageProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState("");
  const [residents, setResidents] = useState<Resident[]>([]);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [selected, setSelected] = useState<Resident | null>(null);
  const [modalEdit, setModalEdit] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    if (!mounted) return;
    getAllData();
  }, [mounted, page, limit, search]);

  const getAllData = () => {
    setIsLoading(true);
    getResidents({ page, limit, search })
      .then((res) => res.json())
      .then((res) => {
        setResidents(res.data);
        setPagination(res.pagination);
      })
      .catch((err) => {
        errorToast(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const update = async () => {
    try {
      setIsLoading(true);
      await editResident(selected!.id, {
        name: selected!.name,
      });
      successToast(`${selected!.name} telah diupdate`);
      setModalEdit(false);
      getAllData();
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <AuthMid>
      <div className="p-4">
        <div className="flex bg-white rounded-xl shadow-sm hover:shadow-md p-4 flex-col">
          <div className="mb-4">
            <h1 className=" text-gray-700 text-2xl">Data Penduduk</h1>
          </div>
          <CustomTable
            pagination
            onSearch={setSearch}
            total={pagination?.total_records}
            limit={limit}
            activePage={page}
            searchHeader={<Button appearance="ghost" onClick={() => fileRef.current?.click()}>Import File</Button>}
            switchHeader={true}
            setActivePage={setPage}
            changeLimit={setLimit}
            headers={[
              "No",
              "NOP",
              "Nama WP",
              "Letak Objek",
              "RT",
              "RW",
              "L. Bumi",
              "L. Bangunan",
              "Kecamatan",
              "Kelurahan",
              "",
            ]}
            headerClasses={["w-8", "", "", "", "w-4"]}
            datasets={residents.map((e) => ({
              cells: [
                { data: (page - 1) * limit + (residents.indexOf(e) + 1) },
                { data: e.nop },
                { data: e.name },
                { data: e.address },
                { data: e.rt },
                { data: e.rw },
                { data: e.surface_area },
                { data: e.building_area },
                { data: `${e.district_code} - ${e.district_name} (${e.district.weight})` },
                { data: `${e.sub_district_code} - ${e.sub_district_name} (${e.sub_district.weight})` },
                {
                  data: (
                    <div className="flex gap-2">
                      {/* <HiOutlinePencilSquare
                        size={16}
                        className="text-blue-400 cursor-pointer"
                        onClick={() => {
                          setSelected(e);
                          setModalEdit(true);
                        }}
                      /> */}
                      {e.is_winner && <TfiCup size={16} className="text-blue-400"/>}
                      {e.is_winner && <LuRefreshCcw size={16} className="text-blue-400 cursor-pointer" onClick={() => {
                        refreshWinner(e.id)
                          .then((res) => getAllData())
                          .catch((err) => errorToast(err));
                      }}/>}
                      <HiOutlineTrash
                        size={16}
                        className="text-red-400 cursor-pointer"
                        onClick={() => {
                          confirmDelete(() => {
                            setIsLoading(true);
                            deleteResident(e.id)
                              .then((res) => getAllData())
                              .catch((err) => errorToast(err))
                              .finally(() => setIsLoading(false));
                          });
                        }}
                      />
                    </div>
                  ),
                },
              ],
            }))}
          />
        </div>
      </div>
      <Modal
        open={modalEdit}
        onClose={() => {
          setModalEdit(false);
        }}
      >
        <Modal.Header>Edit Kecamatan</Modal.Header>
        <Modal.Body>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">
              <label
                htmlFor="name"
                className="text-sm font-medium text-gray-700"
              >
                Nama Kecamatan
              </label>
              <input
                type="text"
                id="name"
                className="border border-gray-300 rounded-md p-2"
                value={selected?.name}
                onChange={(v) => {
                  setSelected({
                    ...selected!,
                    name: v.target.value,
                  });
                }}
              />
            </div>
            {/* <div className="flex flex-col">
                <label
                  htmlFor="code"
                  className="text-sm font-medium text-gray-700"
                >
                  Kode Kecamatan
                </label>
                <input
                  type="text"
                  id="code"
                  className="border border-gray-300 rounded-md p-2"
                  value={selected?.code}
                  onChange={(v) => {
                    setSelected({
                      ...selected!,
                      code: v.target.value,
                    });
                  }}
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="weight"
                  className="text-sm font-medium text-gray-700"
                >
                  Bobot
                </label>
                <input
                  type="text"
                  id="weight"
                  className="border border-gray-300 rounded-md p-2"
                  value={selected?.weight}
                  onChange={(v) => {
                    setSelected({
                      ...selected!,
                      weight: v.target.value ? parseFloat(v.target.value) : 0,
                    });
                  }}
                />
              </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" onClick={update}>
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
      <input
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"  
        type="file"
        className="hidden"
        ref={fileRef}
        onChange={async (el) => {
          try {
            let files = fileRef.current?.files;
            if (files) {
              setIsLoading(true);
              setIsLoading(true);
              var resp = await fileUpload(`admin/resident/import`, {
                file: el.target.files![0],
                dir: "resident",
              });

              await resp.json();
              successToast("Import File Sukses")
              setTimeout(() => {
                  window.location.reload()
              }, 1000);
            }
          } catch (error) {
            errorToast(`${error}`);
          } finally {
            setIsLoading(false);
          }
        }}
      />
    </AuthMid>
  );
};
export default ResidentPage;
