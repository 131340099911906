import { useContext, useEffect, useState, type FC } from "react";
import { HiOutlinePencilSquare, HiOutlineTrash } from "react-icons/hi2";
import { Avatar, Button, Modal, Toggle, Uploader } from "rsuite";
import CustomTable from "../components/CustomTable";
import AuthMid from "../middlewares/AuthMid";
import { Prize } from "../model/prize";
import { LoadingContext } from "../objects/LoadingContext";
import { Pagination } from "../objects/pagination";
import {
  addPrize,
  deletePrize,
  editPrize,
  getPrizes,
} from "../repositories/prize";
import { confirmDelete, getToken } from "../utils/helper";
import { errorToast, successToast, toolTip } from "../utils/helper-ui";
import { GiPodiumWinner } from "react-icons/gi";
import { GoShieldCheck } from "react-icons/go";

interface PrizePageProps {}

const PrizePage: FC<PrizePageProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState("");
  const [prizes, setPrizes] = useState<Prize[]>([]);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [selected, setSelected] = useState<Prize | null>(null);
  const [modalEdit, setModalEdit] = useState(false);
  const [token, setToken] = useState();
  const [path, setPath] = useState("");
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [qty, setQty] = useState(0);
  const [position, setPosition] = useState(0);
  const [isGrandPrize, setIsGrandPrize] = useState(false);

  useEffect(() => {
    setMounted(true);
    getToken().then((res) => {
      setToken(res);
    });
  }, []);
  useEffect(() => {
    if (!mounted) return;
    getAllData();
  }, [mounted, page, limit, search]);

  const getAllData = () => {
    setIsLoading(true);
    getPrizes({ page, limit, search })
      .then((res) => res.json())
      .then((res) => {
        setPrizes(res.data);
        setPagination(res.pagination);
      })
      .catch((err) => {
        errorToast(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const update = async () => {
    try {
      setIsLoading(true);
      if (selected) {
        await editPrize(selected!.id, {
          name: name,
          quantity: qty,
          picture: path,
          position,
          is_grand_prize: isGrandPrize,
        });
        successToast(`${name} telah diupdate`);
      } else {
        await addPrize({
          name: name,
          quantity: qty,
          picture: path,
          position,
          is_grand_prize: isGrandPrize,
        });
        successToast(`${name} telah ditambahkan`);
      }

      setModalEdit(false);
      getAllData();
      clearForm();
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!modalEdit) {
      clearForm();
    }
  }, [modalEdit]);

  const clearForm = () => {
    setPath("");
    setUrl("");
    setName("");
    setQty(0);
    setPosition(0);
    setSelected(null);
  };
  return (
    <AuthMid>
      <div className="p-4">
        <div className="flex bg-white rounded-xl shadow-sm hover:shadow-md p-4 flex-col">
          <div className="mb-4">
            <h1 className=" text-gray-700 text-2xl">Data Hadiah</h1>
          </div>
          <CustomTable
            // pagination
            onSearch={setSearch}
            total={pagination?.total_records}
            // limit={limit}
            // activePage={page}
            // setActivePage={setPage}
            // changeLimit={setLimit}
            headers={["No", "Hadiah", "Foto", "Qty", "Tersedia", ""]}
            headerClasses={["w-8", "", "", "", "w-4"]}
            searchHeader={
              <Button
                appearance="ghost"
                onClick={() => {
                  setModalEdit(true);
                }}
              >
                Tambah Hadiah
              </Button>
            }
            switchHeader={true}
            datasets={prizes.map((e) => ({
              cells: [
                // { data: (page - 1) * limit + (prizes.indexOf(e) + 1) },
                { data: e.position },
                { data: e.name },
                { data: <img src={e.picture_url} className="w-64" /> },
                { data: e.quantity },
                { data: e.available },
                {
                  data: (
                    <div className="flex gap-2">
                      <HiOutlinePencilSquare
                        size={16}
                        className="text-blue-400 cursor-pointer"
                        onClick={() => {
                          setSelected(e);
                          setModalEdit(true);
                          setPath(e.picture);
                          setUrl(e.picture_url);
                          setName(e.name);
                          setQty(e.quantity);
                          setPosition(e.position);
                          setIsGrandPrize(e.is_grand_prize);
                        }}
                      />
                      <HiOutlineTrash
                        size={16}
                        className="text-red-400 cursor-pointer"
                        onClick={() => {
                          confirmDelete(() => {
                            setIsLoading(true);
                            deletePrize(e.id)
                              .then((res) => getAllData())
                              .catch((err) => errorToast(err))
                              .finally(() => setIsLoading(false));
                          });
                        }}
                      />
                      {e.is_grand_prize &&
                        toolTip(
                          "Grand Prize",
                          <GoShieldCheck className="text-violet-400 cursor-pointer" />
                        )}
                    </div>
                  ),
                },
              ],
            }))}
          />
        </div>
      </div>
      <Modal
        open={modalEdit}
        onClose={() => {
          setModalEdit(false);
        }}
      >
        <Modal.Header className="p-4">Form Hadiah</Modal.Header>
        <Modal.Body>
          <div className="flex flex-col gap-2 p-4">
            <div className="flex flex-col">
              <label
                htmlFor="name"
                className="text-sm font-medium text-gray-700"
              >
                Nama
              </label>
              <input
                type="text"
                id="name"
                className="border border-gray-300 rounded-md p-2"
                value={name}
                onChange={(v) => {
                  setName(v.target.value);
                }}
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="qty"
                className="text-sm font-medium text-gray-700"
              >
                Qty
              </label>
              <input
                type="number"
                id="qty"
                className="border border-gray-300 rounded-md p-2"
                value={qty}
                onChange={(v) => {
                  setQty(parseFloat(v.target.value));
                }}
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="qty"
                className="text-sm font-medium text-gray-700"
              >
                Posisi
              </label>
              <input
                type="number"
                id="position"
                className="border border-gray-300 rounded-md p-2"
                value={position}
                onChange={(v) => {
                  setPosition(parseFloat(v.target.value));
                }}
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="isGrandPrize"
                className="text-sm font-medium text-gray-700"
              >
                Grand Prize
              </label>
              <Toggle
                id="isGrandPrize"
                checked={isGrandPrize}
                onChange={(v) => {
                  setIsGrandPrize(v);
                }}
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="picture"
                className="text-sm font-medium text-gray-700"
              >
                Foto
              </label>
              <Uploader
                fileListVisible={false}
                draggable
                action={`${process.env.REACT_APP_API_URL}/admin/file/upload`}
                onUpload={(file) => {
                  setIsLoading(true);
                }}
                headers={{
                  authorization: `Bearer ${token}`,
                }}
                accept="image/*"
                onSuccess={(response, file) => {
                  setPath(response.data.path);
                  setUrl(response.data.url);
                  setIsLoading(false);
                  // toaster.push(<Message type="success">Uploaded successfully</Message>);
                }}
                onError={() => {
                  setIsLoading(false);
                  errorToast("Upload Failed");
                }}
              >
                {url ? (
                  <img src={url} className="w-full" />
                ) : (
                  <div
                    style={{
                      height: 200,
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span>Click or Drag files to this area to upload</span>
                  </div>
                )}
              </Uploader>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="p-4">
          <Button appearance="primary" onClick={update}>
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </AuthMid>
  );
};
export default PrizePage;
