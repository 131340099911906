import { useContext, useEffect, useState, type FC, type ReactNode } from "react";
import { Nav, Sidenav } from "rsuite";
import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import GroupIcon from "@rsuite/icons/legacy/Group";
import MagicIcon from "@rsuite/icons/legacy/Magic";
import GearCircleIcon from "@rsuite/icons/legacy/GearCircle";
import { ExpandedContext } from "../objects/ExpandedContext";
import TopBar from "../components/TopBar";
import SideBar from "../components/SideBar";
import { urlBase64ToUint8Array } from "../utils/helper";
import { subscribeChannel } from "../repositories/auth";

interface AuthMidProps {
  children: ReactNode;
}

const AuthMid: FC<AuthMidProps> = ({ children }) => {
  const { isExpanded, setIsExpanded } = useContext(ExpandedContext);

  useEffect(() => {
    const subscribeUser = async () => {
      try {
        if ("serviceWorker" in navigator) {
          navigator.serviceWorker.register("/sw.js");
          const registration = await navigator.serviceWorker.ready;
          if (registration) {
            const subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(),
            });
            // console.log("User is subscribed:", subscription);
              subscribeChannel(JSON.stringify(subscription));
          }
        }
      } catch (error) {
        // console.error("Failed to subscribe user:", error);
      }
    };

    const requestPermission = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        subscribeUser();
      } else {
        console.log("Notification permission denied");
      }
    };

    requestPermission();
  }, []);
  return (
    <div className="w-screen h-screen">
      <div className="flex flex-row w-full h-full ">
        <div
          className="min-w-[60px]  h-full border-r bg-white"
          style={{ width: isExpanded ? 240 : 60 }}
        >
          <SideBar />
        </div>
        <div className="flex-1 flex flex-col">
          <TopBar />
          <div className="flex-1 overflow-y-auto p-4">{children}</div>
        </div>
      </div>
    </div>
  );
};
export default AuthMid;
