import { useContext, useEffect, useState, type FC } from "react";
import {
    HiOutlinePencilSquare,
    HiOutlineTrash
} from "react-icons/hi2";
import CustomTable from "../components/CustomTable";
import AuthMid from "../middlewares/AuthMid";
import { Winner } from "../model/winner";
import { LoadingContext } from "../objects/LoadingContext";
import { Pagination } from "../objects/pagination";
import {
    deleteWinner,
    getWinners
} from "../repositories/winner";
import { confirmDelete } from "../utils/helper";
import { errorToast } from "../utils/helper-ui";
import { Button } from "rsuite";

interface WinnerPageProps {}

const WinnerPage: FC<WinnerPageProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState("");
  const [winners, setWinners] = useState<Winner[]>([]);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [selected, setSelected] = useState<Winner | null>(null);
  const [modalEdit, setModalEdit] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    if (!mounted) return;
    getAllData();
  }, [mounted, page, limit, search]);

  const getAllData = () => {
    setIsLoading(true);
    getWinners({ page, limit, search })
      .then((res) => res.json())
      .then((res) => {
        setWinners(res.data);
        setPagination(res.pagination);
      })
      .catch((err) => {
        errorToast(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

 
  return (
    <AuthMid>
      <div className="p-4">
        <div className="flex bg-white rounded-xl shadow-sm hover:shadow-md p-4 flex-col">
          <div className="mb-4">
            <h1 className=" text-gray-700 text-2xl">Data Pemenang</h1>
          </div>
          <CustomTable
            pagination
            onSearch={setSearch}
            total={pagination?.total_records}
            limit={limit}
            activePage={page}
            setActivePage={setPage}
            changeLimit={setLimit}
            headers={["No", "Pemenang", "NOP", "Hadiah", ""]}
            headerClasses={["w-8", "", "", "", "w-4"]}
            
            datasets={winners.map((e) => ({
              cells: [
                { data: (page - 1) * limit + (winners.indexOf(e) + 1) },
                { data: e.resident.name },
                { data: e.resident.nop },
                { data: e.prize.name },
                {
                  data: (
                    <div className="flex gap-2">
                
                      <HiOutlineTrash
                        size={16}
                        className="text-red-400 cursor-pointer"
                        onClick={() => {
                          confirmDelete(() => {
                            setIsLoading(true);
                            deleteWinner(e.id)
                              .then((res) => getAllData())
                              .catch((err) => errorToast(err))
                              .finally(() => setIsLoading(false));
                          });
                        }}
                      />
                    </div>
                  ),
                },
              ],
            }))}
          />
        </div>
      </div>
      
    </AuthMid>
  );
};
export default WinnerPage;
