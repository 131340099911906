import { useEffect, useState } from "react";

const Spinner: React.FC<{
  timer: number;
  no: number;
  onFinish: (position: number) => void;
}> = ({ timer, onFinish, no }) => {
  const [position, setPosition] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(timer);
  const [mult , setMult] = useState(0);

  const iconHeight = 70.5;

  useEffect(() => {
    const multiplier = Math.floor(Math.random() * (4 - 1 + 1)) + 1;
    setMult(multiplier)
    setPosition(0 - (multiplier * 705 + iconHeight * (no - 1)));
    //   const start = setStartPosition();
    //   const speed = iconHeight * (no - 1);

    //   let timerId: number | undefined;

    //   const tick = () => {
    //     if (timeRemaining <= 0) {
    //       clearInterval(timerId);
    //       onFinish(position);
    //     } else {
    //       setPosition(position - speed);
    //       setTimeRemaining(timeRemaining - 100);
    //     }
    //   };

    //   timerId = window.setInterval(tick, 100);

    //   return () => clearInterval(timerId);
  }, [timer, onFinish]);

  useEffect(() => {
    console.log(timeRemaining);
  }, [timeRemaining]);

  // const setStartPosition = () =>
  //   ((Math.floor((Math.random() * 9))) * iconHeight) * -1;

  return (
    <>
    <div
      style={{
        backgroundPosition: `0px ${position}px`,
        backgroundImage: "url(/images/sprite3.png)",
        backgroundRepeat: "repeat-y",
      }}
      className="icons"
    />
    </>
  );
};

export default Spinner;
