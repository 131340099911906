import { TOKEN } from "../objects/constants"
import { asyncLocalStorage, clearStorage } from "../utils/helper"

export async function customFetch(...args) {
    let [resource, config, multipart, fullUrl] = args;
    const token = await asyncLocalStorage.getItem(TOKEN)
    if (!config) {
        config = {
            headers: {
                authorization: `Bearer ${token ?? null}`
            }
        }
    } else {
        if (!config["headers"]) {
            config["headers"] = {
                authorization: `Bearer ${token ?? null}`
            }
        }

    }


    if (!multipart) {
        config["headers"]["Content-Type"] = "application/json"
    }


    try {
        // request interceptor here
        const response = await fetch(fullUrl ? resource : `${process.env.REACT_APP_API_URL}/${resource}`, config);

        if (response.status !== 200) {
            var respJson = await response.json()
            throw (respJson.message)
        }

        // response interceptor here
        return response;
    } catch (error) {
        if (`${error}`.toLowerCase().includes("token is expired")) {
            await clearStorage()
            window.location.href = "/login"
        }
        if (error == "No Company ID") {
        }
        return Promise.reject(error)
    }


}