import { Resident, ResidentReq } from "../model/resident";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/http";

export let getResidents = async (pagination: PaginationReq) => {
  var params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
    search: pagination.search,
  };
  return await customFetch(`admin/resident?${new URLSearchParams(params)}`);
};

export let editResident = async (id: string, req: ResidentReq) => {
  return await customFetch(`admin/resident/${id}`, {
    method: "PUT",
    body: JSON.stringify(req),
  });
};
export let refreshWinner = async (id: string) => {
  return await customFetch(`admin/resident/${id}`, {
    method: "PUT",
    body: JSON.stringify({is_winner: false}),
  });
};
export let deleteResident = async (id: string) => {
  return await customFetch(`admin/resident/${id}`, {
    method: "DELETE",
  });
};
