import type { FC } from "react";

interface NumberWrapperProps {
  no: number;
  className: string;
}

const NumberWrapper: FC<NumberWrapperProps> = ({ no, className }) => {
  return (
    <div className=" ">
      <div
        className={`w-[28px] h-[28px] 
     ${className}
      rounded-lg flex items-center justify-center font-bold text-[16pt] relative`}
      >
        <span className="relative z-10">{no}</span>
        {className == "number-ready" && (
          <span className="absolute top-[-3px] left-[6px] text-[16pt] text-black opacity-30 transform translate-x-1 translate-y-1">
            {no}
          </span>
        )}
      </div>
    </div>
  );
};
export default NumberWrapper;
