import type { FC } from "react";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import ResidentPage from "../pages/Resident";
import DistrictPage from "../pages/District";
import SubDistrictPage from "../pages/SubDistrict";
import DrawPage from "../pages/Draw";
import SlotMachine from "../pages/SlotMachine";
import PrizePage from "../pages/Prize";
import WinnerPage from "../pages/Winner";
import LotteryPage from "../pages/LotteryPage";
import LoaderBoard from "../pages/LoaderBoard";

interface PrivateRoutesProps {}

const PrivateRoutes: FC<PrivateRoutesProps> = ({}) => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/resident" element={<ResidentPage />} />
      <Route path="/district" element={<DistrictPage />} />
      <Route path="/sub_district" element={<SubDistrictPage />} />
      {/* <Route path="/draw" element={<DrawPage />} /> */}
      <Route path="/slot" element={<SlotMachine />} />
      <Route path="/prize" element={<PrizePage />} />
      <Route path="/winner" element={<WinnerPage />} />
      <Route path="/lottery" element={<LotteryPage />} />
      <Route path="/leaderboard" element={<LoaderBoard />} />
      <Route path="/login" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
export default PrivateRoutes;
