import { LoginReq } from "../model/Login";
import { customFetch } from "../utils/http";

export let postLogin = async (req: LoginReq) => {
  return await customFetch("admin/login", {
    method: "POST",
    body: JSON.stringify(req),
  });
};


export const subscribeChannel = async (subscribe_token: string) => {
  return await customFetch("admin/subscribe", {
    method: "POST",
    body: JSON.stringify({ subscribe_token }),
  });
};