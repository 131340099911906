import { useContext, useEffect, useState, type FC } from "react";
import { Nav, Sidenav } from "rsuite";
import { ExpandedContext } from "../objects/ExpandedContext";
import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import GroupIcon from "@rsuite/icons/legacy/Group";
import LocationArrow from "@rsuite/icons/legacy/LocationArrow";
import MapPin from "@rsuite/icons/legacy/MapPin";
import MagicIcon from "@rsuite/icons/legacy/Magic";
import GearCircleIcon from "@rsuite/icons/legacy/GearCircle";
import { useLocation, useNavigate } from "react-router-dom";
import { IoRibbonOutline } from "react-icons/io5";
import { GiChampions } from "react-icons/gi";
import { IoGameControllerOutline } from "react-icons/io5";

interface SideBarProps {}

const SideBar: FC<SideBarProps> = ({}) => {
  const { isExpanded, setIsExpanded } = useContext(ExpandedContext);
  const loc = useLocation();
  const nav = useNavigate();
  const [activeKey, setActiveKey] = useState("/");
  useEffect(() => {
    setActiveKey(loc.pathname);
  }, [loc]);

  return (
    <Sidenav expanded={isExpanded} defaultOpenKeys={["3", "4"]} >
      <Sidenav.Header>
        <div
          className="flex justify-center items-center w-full h-full p-4 bg-white"
          style={{ cursor: "pointer" }}
        >
          {isExpanded ? (
            <img src="/images/bapenda-kota-bandung.jpg" alt="logo" />
          ) : (
            <img src="/images/logo.jpg" alt="logo" />
          )}
        </div>
      </Sidenav.Header>
      <Sidenav.Body>
        <Nav activeKey={activeKey} onSelect={setActiveKey}>
          <Nav.Item eventKey="/" icon={<DashboardIcon />} onClick={() => nav("/")}>
            Dashboard
          </Nav.Item>
          <Nav.Item eventKey="/resident" icon={<GroupIcon />} onClick={() => nav("/resident")}>
            Data Penduduk
          </Nav.Item>
          <Nav.Item eventKey="/district" icon={<LocationArrow />} onClick={() => nav("/district")}>
            Data Kecamatan
          </Nav.Item>
          <Nav.Item eventKey="/sub_district" icon={<MapPin />} onClick={() => nav("/sub_district")}>
            Data Kelurahan
          </Nav.Item>
          <Nav.Item eventKey="/prize" icon={<IoRibbonOutline className="rs-icon" />} onClick={() => nav("/prize")}>
            Hadiah
          </Nav.Item>
          <Nav.Item eventKey="/winner" icon={<GiChampions className="rs-icon"  />} onClick={() => nav("/winner")}>
            Pemenang
          </Nav.Item>
          <Nav.Item eventKey="/winner" icon={<IoGameControllerOutline className="rs-icon"  />} onClick={() => window.open("/lottery")}>
            Undi
          </Nav.Item>
          
        </Nav>
      </Sidenav.Body>
    </Sidenav>
  );
};
export default SideBar;
