import type { FC } from "react";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

interface AppRoutesProps {}

const AppRoutes: FC<AppRoutesProps> = ({}) => {
  const token = localStorage.getItem("token");
  return (
    <BrowserRouter>
      <Routes>
        {token ? (
          <Route path="/*" element={<PrivateRoutes />} />
        ) : (
          <Route path="/*" element={<PublicRoutes />} />
        )}
      </Routes>
    </BrowserRouter>
  );
};
export default AppRoutes;
