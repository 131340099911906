import React, { Suspense, useState } from "react";
import logo from "./logo.svg";
import AppRoutes from "./routes/AppRoutes";
import { LoadingContext } from "./objects/LoadingContext";
import GeneralMid from "./middlewares/GeneralMid";
import { ExpandedContext } from "./objects/ExpandedContext";

function App() {
  const [loading, setLoading] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(true);
  return (
    <Suspense>
      <LoadingContext.Provider
        value={{ isLoading: loading, setIsLoading: setLoading }}
      >
        <ExpandedContext.Provider
          value={{ isExpanded: expanded, setIsExpanded: setExpanded }}
        >
          <GeneralMid>
            <AppRoutes />
          </GeneralMid>
        </ExpandedContext.Provider>
      </LoadingContext.Provider>
    </Suspense>
  );
}

export default App;
