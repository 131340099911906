import { createContext } from "react";

export const ExpandedContext = createContext<ExpandedContextType>({
  isExpanded: true,
  setIsExpanded: (val) => false,
});

export type ExpandedContextType = {
  isExpanded: boolean;
  setIsExpanded: (val: boolean) => void;
};
