import { useEffect, useState, type FC } from "react";
import { getPrizes } from "../repositories/prize";
import { Prize } from "../model/prize";
import { useNavigate } from "react-router-dom";

interface LoaderBoardProps {}

const LoaderBoard: FC<LoaderBoardProps> = ({}) => {
  const [mounted, setMounted] = useState(false);
  const [prizes, setPrizes] = useState<Prize[]>([]);
  const nav = useNavigate()

  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    if (!mounted) return;
    getAllPrizes();
  }, [mounted]);

  const getAllPrizes = () => {
    getPrizes({ page: 1, limit: 0, search: "" })
      .then((res) => res.json())
      .then((res) => {
        setPrizes(res.data);
      });
  };

  return (
    <div
      className="w-full h-screen max-w-lg mx-auto aspect-[1080/1920] relative  p-4 overflow-auto"
      style={{
        backgroundImage: "url(/images/winning-bg.png)",
        // backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "50% 45px",
      }}
    >
      <img src="/images/back.png" alt=""  className=" cursor-pointer z-50 fixed bottom-2 w-10"  onClick={() => nav(-1)}/>
      {prizes.map((e) => (
        <div className="w-full relative" key={e.id}>
          <div className=" p-8 rounded-lg max-w-md mx-auto">
            {/* Judul Header */}
            <div className="bg-blue-800 text-white rounded-full text-center py-2 px-4 absolute top-0 left-0 text-2xl font-bold">
            {e.quantity} {e.name}
            </div>


                <img
                  src={e?.picture_url}
                  alt=""
                  className="w-[120px] absolute bottom-2 right-2"
                />

            {/* Daftar Pemenang */}
            <div className="bg-white shadow-md rounded-lg p-6 min-h-[200px]">
              <ul>
                {e.winners.map((winner) => (
                  <li
                    key={winner.id}
                    className=""
                  >
                    <p className="text-2xl text-blue-800 font-bold mb-0">
                    {winner.resident.name}
                    </p>
                    <p className="text-normal text-blue-800 font-bold mb-4">
                    {winner.resident.address}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default LoaderBoard;
