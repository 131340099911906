import { useContext, useEffect, useState, type FC } from "react";
import AuthMid from "../middlewares/AuthMid";
import { LoadingContext } from "../objects/LoadingContext";
import { errorToast, successToast } from "../utils/helper-ui";
import {
  deleteDistrict,
  editDistrict,
  getDistricts,
} from "../repositories/district";
import CustomTable from "../components/CustomTable";
import { District } from "../model/district";
import { Pagination } from "../objects/pagination";
import {
  HiOutlinePencilSquare,
  HiOutlineTrash,
  HiTrash,
} from "react-icons/hi2";
import { Button, Modal } from "rsuite";
import { confirmDelete } from "../utils/helper";

interface DistrictPageProps {}

const DistrictPage: FC<DistrictPageProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState("");
  const [districts, setDistricts] = useState<District[]>([]);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [selected, setSelected] = useState<District | null>(null);
  const [modalEdit, setModalEdit] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    if (!mounted) return;
    getAllData();
  }, [mounted, page, limit, search]);

  const getAllData = () => {
    setIsLoading(true);
    getDistricts({ page, limit, search })
      .then((res) => res.json())
      .then((res) => {
        setDistricts(res.data);
        setPagination(res.pagination);
      })
      .catch((err) => {
        errorToast(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const update = async () => {
    try {
      setIsLoading(true);
      await editDistrict(selected!.id, {
        name: selected!.name,
        code: selected!.code,
        weight: selected!.weight,
      });
      successToast(`${selected!.name} telah diupdate`);
      setModalEdit(false);
      getAllData();
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <AuthMid>
      <div className="p-4">
        <div className="flex bg-white rounded-xl shadow-sm hover:shadow-md p-4 flex-col">
          <div className="mb-4">
            <h1 className=" text-gray-700 text-2xl">Data Kecamatan</h1>
          </div>
          <CustomTable
            pagination
            onSearch={setSearch}
            total={pagination?.total_records}
            limit={limit}
            activePage={page}
            setActivePage={setPage}
            changeLimit={setLimit}
            headers={["No", "Nama Kecamatan", "Kode Kecamatan", "Bobot", ""]}
            headerClasses={["w-8", "", "", "", "w-4"]}
            datasets={districts.map((e) => ({
              cells: [
                { data: (page - 1) * limit + (districts.indexOf(e) + 1) },
                { data: e.name },
                { data: e.code },
                { data: e.weight },
                {
                  data: (
                    <div className="flex gap-2">
                      <HiOutlinePencilSquare
                        size={16}
                        className="text-blue-400 cursor-pointer"
                        onClick={() => {
                          setSelected(e);
                          setModalEdit(true);
                        }}
                      />
                      <HiOutlineTrash
                        size={16}
                        className="text-red-400 cursor-pointer"
                        onClick={() => {
                          confirmDelete(() => {
                            setIsLoading(true);
                            deleteDistrict(e.id)
                              .then((res) => getAllData())
                              .catch((err) => errorToast(err))
                              .finally(() => setIsLoading(false));
                          });
                        }}
                      />
                    </div>
                  ),
                },
              ],
            }))}
          />
        </div>
      </div>
      <Modal
        open={modalEdit}
        onClose={() => {
          setModalEdit(false);
        }}
      >
        <Modal.Header className="p-4">Edit Kecamatan</Modal.Header>
        <Modal.Body>
          <div className="flex flex-col gap-2 p-4">
            <div className="flex flex-col">
              <label
                htmlFor="name"
                className="text-sm font-medium text-gray-700"
              >
                Nama Kecamatan
              </label>
              <input
                type="text"
                id="name"
                className="border border-gray-300 rounded-md p-2"
                value={selected?.name}
                onChange={(v) => {
                  setSelected({
                    ...selected!,
                    name: v.target.value,
                  });
                }}
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="code"
                className="text-sm font-medium text-gray-700"
              >
                Kode Kecamatan
              </label>
              <input
                type="text"
                id="code"
                className="border border-gray-300 rounded-md p-2"
                value={selected?.code}
                onChange={(v) => {
                  setSelected({
                    ...selected!,
                    code: v.target.value,
                  });
                }}
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="weight"
                className="text-sm font-medium text-gray-700"
              >
                Bobot
              </label>
              <input
                type="text"
                id="weight"
                className="border border-gray-300 rounded-md p-2"
                value={selected?.weight}
                onChange={(v) => {
                  setSelected({
                    ...selected!,
                    weight: v.target.value ? parseFloat(v.target.value) : 0,
                  });
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="p-4">
          <Button appearance="primary" onClick={update}>
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </AuthMid>
  );
};
export default DistrictPage;
