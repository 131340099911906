import { Prize, PrizeReq } from "../model/prize";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/http";

export let getPrizes = async (pagination: PaginationReq, isGrandPrize?: boolean | null) => {
  var params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
    search: pagination.search,
    
  };
  if (isGrandPrize != null) {
    params["is_grand_prize"] = isGrandPrize ? "1" : "0";
  }
  return await customFetch(`admin/prize?${new URLSearchParams(params)}`);
};


export let addPrize = async ( req: PrizeReq) => {
  return await customFetch(`admin/prize`, {
    method: "POST",
    body: JSON.stringify(req),
  });
}
export let editPrize = async (id: string, req: PrizeReq) => {
  return await customFetch(`admin/prize/${id}`, {
    method: "PUT",
    body: JSON.stringify(req),
  });
}
export let deletePrize = async (id: string, ) => {
  return await customFetch(`admin/prize/${id}`, {
    method: "DELETE",
  });
}