import { FormEvent, useContext, useState, type FC } from "react";
import { Button, Input, InputGroup } from "rsuite";
import EyeIcon from "@rsuite/icons/legacy/Eye";
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";
import { errorToast } from "../utils/helper-ui";
import { postLogin } from "../repositories/auth";
import { LoadingContext } from "../objects/LoadingContext";
import { setStorageToken } from "../utils/helper";

interface LoginProps {}

const LoginPage: FC<LoginProps> = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const {isLoading, setIsLoading} = useContext(LoadingContext)

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{6,}$/;

    if (!emailRegex.test(email)) {
      errorToast("Invalid email");
      return;
    }

    if (!passwordRegex.test(password)) {
      errorToast("Password must be at least 6 characters and contain at least one uppercase letter");
      return;
    }
    console.log(email, password)
    try {
        setIsLoading(true)
        let resp = await postLogin({email, password})
        let respJson = await resp.json()
        setStorageToken(respJson.token)
    } catch (error) {
        errorToast(`${error}`)
    } finally {
        setIsLoading(false)
    }
  };

  const styles = {
    width: 300
  };

  return (
    <div className="flex  flex-col  justify-center items-center w-full h-full bg-white">
      <div className=" w-[400px] h-full p-8 flex justify-center items-center flex-col">
        <img src="/images/bapenda-kota-bandung.jpg" alt="Logo Bapenda" className="w-48" />
        <form className="flex flex-col gap-4 mt-10" onSubmit={handleSubmit}>
          <div>
            <label className="text-sm" htmlFor="email">Email</label>
            <Input placeholder="Email ...." value={email} onChange={(e) => setEmail(e)} style={styles}  />
          </div>
          <div>
            <label className="text-sm" htmlFor="password">Password</label>
            <InputGroup inside  style={styles}>
              <Input placeholder="* * * * * * * *" type={visible ? "text" : "password"} onChange={(e) => setPassword(e)} />
              <InputGroup.Button onClick={() => setVisible(!visible)}>
                {visible ? <EyeIcon /> : <EyeSlashIcon />}
              </InputGroup.Button>
            </InputGroup>
          </div>
          <Button className="mt-4" appearance="ghost" type="submit">Log In</Button>
          <div className="h-80"></div>
        </form>
      </div>
    </div>
  );
};
export default LoginPage;
