import { useContext, type FC, type ReactNode } from "react";
import { LoadingContext } from "../objects/LoadingContext";

interface GeneralMidProps {
  children: ReactNode;
}

const GeneralMid: FC<GeneralMidProps> = ({ children }) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);

  return (
    <div className="relative w-full h-full">
      {children}
      {isLoading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex w-full h-full justify-center items-center bg-white bg-opacity-50">
          <img src="/images/loading.gif" alt="" className="w-24" />
        </div>
      )}
    </div>
  );
};
export default GeneralMid;
