import type { FC } from "react";
import Spinner from "../components/Spinner";

interface SlotMachineProps {}

const SlotMachine: FC<SlotMachineProps> = ({}) => {
  return (
    <Spinner
      no={0}
      timer={10000}
      onFinish={function (position: number): void {
        console.log("position", position)
        // throw new Error("Function not implemented.");
      }}
    />
  );
};
export default SlotMachine;
