import { useContext, type FC } from 'react';
import { CgMenuLeftAlt } from "react-icons/cg";
import { ExpandedContext } from '../objects/ExpandedContext';
import { IoMdLogOut } from "react-icons/io";
import { clearStorage } from '../utils/helper';

interface TopBarProps {}

const TopBar: FC<TopBarProps> = ({}) => {
  const { isExpanded, setIsExpanded } = useContext(ExpandedContext);

        return (
            <div className=" py-4 px-4 border-b bg-white flex justify-between flex-row">
                <CgMenuLeftAlt className='cursor-pointer' onClick={() => {
                    setIsExpanded(!isExpanded)
                }} />
                <IoMdLogOut className='cursor-pointer' onClick={() => {
                    clearStorage()
                    window.location.reload()
                }} />
            </div>
        );
}
export default TopBar;