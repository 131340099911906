import type { FC } from "react";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "../pages/Login";

interface PublicRoutesProps {}

const PublicRoutes: FC<PublicRoutesProps> = ({}) => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};
export default PublicRoutes;
