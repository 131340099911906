import { Winner, WinnerReq } from "../model/winner";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/http";

export let getWinners = async (pagination: PaginationReq) => {
  var params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
    search: pagination.search
  };
  return await customFetch(`admin/winner?${new URLSearchParams(params)}`);
};


export let addWinner = async ( req: WinnerReq) => {
  return await customFetch(`admin/winner`, {
    method: "POST",
    body: JSON.stringify(req),
  });
}
export let editWinner = async (id: string, req: WinnerReq) => {
  return await customFetch(`admin/winner/${id}`, {
    method: "PUT",
    body: JSON.stringify(req),
  });
}
export let deleteWinner = async (id: string, ) => {
  return await customFetch(`admin/winner/${id}`, {
    method: "DELETE",
  });
}