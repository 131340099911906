import type { FC } from 'react';
import AuthMid from '../middlewares/AuthMid';

interface HomeProps {}

const Home: FC<HomeProps> = ({}) => {
        return (
            <AuthMid>
                Home
            </AuthMid>
        );
}
export default Home;